var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _State_brazeDeviceId, _State_deviceId, _State_experimentVariant, _State_commentBlockHeight, _State_experimentAccepted, _State_readerConsentAdvertising, _State_readerConsentProductAndPhilanthropy, _State_referrer, _State_searchQuery, _State_searchSort, _State_searchPageNumber, _State_searchQtyPerPage, _State_userId, _State_pageView, _State_pageType, _State_pageStoryPublicationType, _State_pageSection, _State_pageSubSection, _State_pageObjectId, _State_pageParentId, _State_podcastId, _State_podcastSeasonId;
class State {
    constructor() {
        _State_brazeDeviceId.set(this, void 0);
        _State_deviceId.set(this, void 0);
        _State_experimentVariant.set(this, void 0);
        _State_commentBlockHeight.set(this, void 0);
        _State_experimentAccepted.set(this, void 0);
        _State_readerConsentAdvertising.set(this, void 0);
        _State_readerConsentProductAndPhilanthropy.set(this, void 0);
        _State_referrer.set(this, void 0);
        _State_searchQuery.set(this, void 0);
        _State_searchSort.set(this, void 0);
        _State_searchPageNumber.set(this, void 0);
        _State_searchQtyPerPage.set(this, void 0);
        _State_userId.set(this, void 0);
        _State_pageView.set(this, void 0);
        _State_pageType.set(this, void 0);
        _State_pageStoryPublicationType.set(this, void 0);
        _State_pageSection.set(this, void 0);
        _State_pageSubSection.set(this, void 0);
        _State_pageObjectId.set(this, void 0);
        _State_pageParentId.set(this, void 0);
        _State_podcastId.set(this, void 0);
        _State_podcastSeasonId.set(this, void 0);
    }
    getDeviceId() {
        return __classPrivateFieldGet(this, _State_deviceId, "f");
    }
    getExperimentVariant() {
        return __classPrivateFieldGet(this, _State_experimentVariant, "f");
    }
    setExperimentVariant(value) {
        __classPrivateFieldSet(this, _State_experimentVariant, value, "f");
    }
    setCommentBlockHeight(value) {
        __classPrivateFieldSet(this, _State_commentBlockHeight, value, "f");
    }
    getCommentBlockHeight() {
        return __classPrivateFieldGet(this, _State_commentBlockHeight, "f");
    }
    getBrazeDeviceId() {
        return __classPrivateFieldGet(this, _State_brazeDeviceId, "f") || null;
    }
    getReaderConsentAdvertising() {
        return __classPrivateFieldGet(this, _State_readerConsentAdvertising, "f");
    }
    getReaderConsentProductAndPhilanthropy() {
        return __classPrivateFieldGet(this, _State_readerConsentProductAndPhilanthropy, "f");
    }
    getReferrer() {
        return __classPrivateFieldGet(this, _State_referrer, "f");
    }
    getSearchQuery() {
        return __classPrivateFieldGet(this, _State_searchQuery, "f");
    }
    getSearchSort() {
        return __classPrivateFieldGet(this, _State_searchSort, "f");
    }
    getSearchPageNumber() {
        return __classPrivateFieldGet(this, _State_searchPageNumber, "f");
    }
    getSearchQtyPerPage() {
        return __classPrivateFieldGet(this, _State_searchQtyPerPage, "f");
    }
    getUserId() {
        return __classPrivateFieldGet(this, _State_userId, "f");
    }
    setDeviceId(value) {
        __classPrivateFieldSet(this, _State_deviceId, value, "f");
    }
    setBrazeDeviceId(value) {
        __classPrivateFieldSet(this, _State_brazeDeviceId, value, "f");
    }
    setReaderConsentAdvertising(value) {
        __classPrivateFieldSet(this, _State_readerConsentAdvertising, value, "f");
    }
    setReaderConsentProductAndPhilanthropy(value) {
        __classPrivateFieldSet(this, _State_readerConsentProductAndPhilanthropy, value, "f");
    }
    setReferrer(value) {
        __classPrivateFieldSet(this, _State_referrer, value, "f");
    }
    setSearchQuery(value) {
        __classPrivateFieldSet(this, _State_searchQuery, value, "f");
    }
    setSearchSort(value) {
        __classPrivateFieldSet(this, _State_searchSort, value, "f");
    }
    setSearchPageNumber(value) {
        __classPrivateFieldSet(this, _State_searchPageNumber, value, "f");
    }
    setSearchQtyPerPage(value) {
        __classPrivateFieldSet(this, _State_searchQtyPerPage, value, "f");
    }
    setUserId(value) {
        __classPrivateFieldSet(this, _State_userId, value, "f");
    }
    getPageView() {
        return __classPrivateFieldGet(this, _State_pageView, "f");
    }
    setPageView(value) {
        __classPrivateFieldSet(this, _State_pageView, value, "f");
    }
    getPageType() {
        return __classPrivateFieldGet(this, _State_pageType, "f");
    }
    setPageType(value) {
        __classPrivateFieldSet(this, _State_pageType, value, "f");
    }
    getPageStoryPublicationType() {
        return __classPrivateFieldGet(this, _State_pageStoryPublicationType, "f");
    }
    setPageStoryPublicationType(value) {
        __classPrivateFieldSet(this, _State_pageStoryPublicationType, value, "f");
    }
    getPageSection() {
        return __classPrivateFieldGet(this, _State_pageSection, "f");
    }
    setPageSection(value) {
        __classPrivateFieldSet(this, _State_pageSection, value, "f");
    }
    getPageSubSection() {
        return __classPrivateFieldGet(this, _State_pageSubSection, "f");
    }
    setPageSubSection(value) {
        __classPrivateFieldSet(this, _State_pageSubSection, value, "f");
    }
    getPageObjectId() {
        return __classPrivateFieldGet(this, _State_pageObjectId, "f");
    }
    setPageObjectId(value) {
        __classPrivateFieldSet(this, _State_pageObjectId, value, "f");
    }
    getPageParentId() {
        return __classPrivateFieldGet(this, _State_pageParentId, "f");
    }
    setPageParentId(value) {
        __classPrivateFieldSet(this, _State_pageParentId, value, "f");
    }
    getIsDarkModeEnabled() {
        return window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
    getPodcastId() {
        var _a;
        return (_a = __classPrivateFieldGet(this, _State_podcastId, "f")) !== null && _a !== void 0 ? _a : null;
    }
    setPodcastId(value) {
        __classPrivateFieldSet(this, _State_podcastId, value, "f");
    }
    getPodcastSeasonId() {
        var _a;
        return (_a = __classPrivateFieldGet(this, _State_podcastSeasonId, "f")) !== null && _a !== void 0 ? _a : null;
    }
    setPodcastSeasonId(value) {
        __classPrivateFieldSet(this, _State_podcastSeasonId, value, "f");
    }
}
_State_brazeDeviceId = new WeakMap(), _State_deviceId = new WeakMap(), _State_experimentVariant = new WeakMap(), _State_commentBlockHeight = new WeakMap(), _State_experimentAccepted = new WeakMap(), _State_readerConsentAdvertising = new WeakMap(), _State_readerConsentProductAndPhilanthropy = new WeakMap(), _State_referrer = new WeakMap(), _State_searchQuery = new WeakMap(), _State_searchSort = new WeakMap(), _State_searchPageNumber = new WeakMap(), _State_searchQtyPerPage = new WeakMap(), _State_userId = new WeakMap(), _State_pageView = new WeakMap(), _State_pageType = new WeakMap(), _State_pageStoryPublicationType = new WeakMap(), _State_pageSection = new WeakMap(), _State_pageSubSection = new WeakMap(), _State_pageObjectId = new WeakMap(), _State_pageParentId = new WeakMap(), _State_podcastId = new WeakMap(), _State_podcastSeasonId = new WeakMap();
export default State;
