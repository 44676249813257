import SendWebScrollEvent from "../Events/WebScrollEvent";
export default class {
    constructor(pubSub, contextsHandler) {
        const eventName = "scrolledToBlockItem";
        pubSub.subscribe(eventName, (eventName, eventData) => {
            SendWebScrollEvent({
                eventAction: eventName,
                name: eventData.name,
                itemId: eventData.reach.itemId,
                itemType: eventData.reach.itemType,
                position: eventData.reach.position,
            }, contextsHandler);
        });
    }
}
