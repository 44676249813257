import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";
export default function (eventData, contextsHandler) {
    trackSelfDescribingEvent({
        event: {
            schema: "iglu:ca.lapresse/event_web_time_spent/jsonschema/1-0-4",
            data: eventData,
        },
        context: [
            ...contextsHandler.getGlobalContexts(),
        ]
    });
}
