export default class {
    constructor() {
        this.subscribers = {};
    }
    publish(eventName, eventData = {}) {
        if (!this.hasSubscribers(eventName)) {
            return;
        }
        this.subscribers[eventName].forEach((eventHandler) => {
            try {
                eventHandler(eventName, eventData);
            }
            catch (error) {
                console.warn("Un problème est survenu avec l'événement %o.", eventName);
                console.debug("Le problème %o.", error);
            }
        });
    }
    subscribe(eventName, eventHandler) {
        if (!this.subscribers[eventName]) {
            this.subscribers[eventName] = [];
        }
        this.subscribers[eventName].push(eventHandler);
    }
    hasSubscribers(eventName) {
        return this.subscribers[eventName] && Array.isArray(this.subscribers[eventName]) && this.subscribers[eventName].length > 0;
    }
}
