import SendPageViewHeartbeatEvent from "../Events/PageViewHeartbeatEvent";
export default class {
    constructor(pubSub, contextsHandler) {
        pubSub.subscribe("pageViewHeartbeat", (eventName, eventData) => {
            SendPageViewHeartbeatEvent({
                "elapsedTime": eventData.elapsedTime,
            }, contextsHandler);
        });
    }
}
