import AudioReadyEvent from "../Events/AudioReadyEvent";
export default class {
    constructor(pubSub, contextsHandler) {
        pubSub.subscribe("audioReady", (eventName, eventData) => {
            AudioReadyEvent({
                "eventAction": "audioReady",
                "audioId": eventData.audioId,
                "audioUrl": eventData.audioUrl,
                "audioUrlHash": eventData.audioUrlHash,
                "audioTitle": eventData.audioTitle,
                "audioType": eventData.audioType,
                "durationInMS": eventData.durationInMS,
            }, contextsHandler);
        });
    }
}
