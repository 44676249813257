import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";
export default function (contextsHandler) {
    trackSelfDescribingEvent({
        event: {
            schema: "iglu:ca.lapresse/event_web_login_open/jsonschema/1-0-1",
            data: {},
        },
        context: [
            ...contextsHandler.getGlobalContexts(),
        ]
    });
}
