import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";
export default function (eventData, contextsHandler) {
    trackSelfDescribingEvent({
        event: {
            schema: "iglu:ca.lapresse/event_web_audio_player_ready/jsonschema/2-1-0",
            data: eventData,
        },
        context: [
            ...contextsHandler.getGlobalContexts(),
            contextsHandler.getPodcastContext(),
        ]
    });
}
