import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";
export default function (eventData, contextsHandler) {
    trackSelfDescribingEvent({
        event: {
            schema: "iglu:ca.lapresse/event_web_experiment_completed/jsonschema/1-0-0",
            data: eventData,
        },
        context: [
            ...contextsHandler.getGlobalContexts(),
        ],
    });
}
