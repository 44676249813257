import SendPostViewedEvent from "../Events/PostViewedEvent";
export default class {
    constructor(pubSub, contextsHandler) {
        pubSub.subscribe("postViewed", (eventName, eventData) => {
            SendPostViewedEvent({
                "storyId": eventData.storyId,
            }, contextsHandler);
        });
    }
}
