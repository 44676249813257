import AudioMarkReachedEvent from "../Events/AudioMarkReachedEvent";
export default class {
    constructor(pubSub, contextsHandler) {
        pubSub.subscribe("audioMarkReached", (eventName, eventData) => {
            AudioMarkReachedEvent({
                "eventAction": "audioMarkReached",
                "audioId": eventData.audioId,
                "audioUrl": eventData.audioUrl,
                "audioUrlHash": eventData.audioUrlHash,
                "audioTitle": eventData.audioTitle,
                "audioType": eventData.audioType,
                "playbackPosition": eventData.position,
                "mark": eventData.mark,
            }, contextsHandler);
        });
    }
}
