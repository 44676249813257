import AudioPlaybackChangedEvent from "../Events/AudioPlaybackChangedEvent";
export default class {
    constructor(pubSub, contextsHandler) {
        pubSub.subscribe("audioStarted", (eventName, eventData) => {
            AudioPlaybackChangedEvent({
                "action": "start",
                "eventAction": "audioStarted",
                "audioId": eventData.audioId,
                "audioUrl": eventData.audioUrl,
                "audioUrlHash": eventData.audioUrlHash,
                "audioTitle": eventData.audioTitle,
                "audioType": eventData.audioType,
                "triggeredBy": eventData.triggeredBy,
            }, contextsHandler);
        });
    }
}
